import { Fragment, useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import {
  Header,
  IncomingMessage,
  Message,
  OutgoingMessage,
  Profile,
  Typing,
} from "..";
import { obj, responses } from "../../data/questions";
import { UserSelection, Journey, Ans } from "./types";
import { getCurrentDateAndTime } from "../../utils/getCurrentDateAndTime";

import "./chatbox.scss";

const ChatBox = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLastLoading, setIsLastLoading] = useState(false);
  const [index, setIndex] = useState<number>(0);
  const [selectedJourney, setSelectedJourney] = useState<Journey>("welcome");
  const [isJourneySelected, setIsJourneySelected] = useState(false);
  const [userSelection, setUserSelection] = useState<UserSelection>({});
  const [userAnswers, setUserAnswers] = useState<UserSelection>({});
  const [data, setData] = useState(obj[selectedJourney] || []);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const [userid, token, fname] = [
    localStorage.getItem("userid"),
    localStorage.getItem("token"),
    localStorage.getItem("firstname"),
  ];

  const getKeyByValue = (obj: Ans, value: string) =>
    Object.keys(obj).find((key) => obj[key as keyof typeof obj] === value);

  const getPredominantChoice = () => {
    const choiceCounts = { A: 0, B: 0, C: 0 };

    for (const questionId in userAnswers) {
      const answer = userAnswers[questionId].answer;
      for (const choice in userAnswers[questionId].answers) {
        if (userAnswers[questionId].answers[choice as keyof Ans] === answer) {
          choiceCounts[choice as keyof Ans]++;
          break;
        }
      }
    }

    const maxCount = Math.max(choiceCounts.A, choiceCounts.B, choiceCounts.C);

    // Check if the predominant choice is less than half of the total responses

    if (
      maxCount * 2 <=
      Object.values(choiceCounts).reduce((a, b) => a + b, 0)
    ) {
      return "D";
    }

    return Object.keys(choiceCounts).find(
      (choice) => choiceCounts[choice as keyof typeof choiceCounts] === maxCount
    );
  };

  useEffect(() => {
    if (index === 2 && !isJourneySelected) {
      const val = obj[selectedJourney][2];
      setUserSelection((prev) => ({
        ...prev,
        [val.question]: {
          answer: "",
          option: "",
          ...val,
        },
      }));
      setIndex(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const scrollToBottom = () =>
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

  useEffect(() => {
    scrollToBottom();
  }, [userSelection, data, index, isLoading, isLastLoading]);

  const selectUserJourney = (answer: string) => {
    setIsJourneySelected(true);
    setSelectedJourney(answer as keyof typeof obj);
    setIsJourneySelected(true);
    setData(obj[answer as Journey]);
    setIndex(0);
    const val = obj["welcome"][3];
    setUserSelection((prev) => ({
      ...prev,
      [val.question]: {
        answer: answer,
        option: getKeyByValue(val.answers, answer),
        ...val,
      },
    }));
  };

  useEffect(() => {
    if (index === data.length) {
      const mostlySelected = getPredominantChoice();

      if (selectedJourney !== "welcome") {
        const val =
          responses[selectedJourney as keyof typeof responses][
            mostlySelected as keyof Ans
          ];
        setData((prev) => [
          ...prev,
          {
            question: val,
            answers: {
              A: "",
              B: "",
              C: "",
            },
          },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const handleAnswerSelections = (answer: string) => {
    if (index === 3 && !isJourneySelected) {
      selectUserJourney(answer);
      return;
    }

    if (index < data.length) {
      setIndex((prev) => prev + 1);
      const val = data[index];
      setUserSelection((prev) => ({
        ...prev,
        [val.question]: {
          answer: answer,
          option: getKeyByValue(val.answers, answer),
          ...val,
        },
      }));
      if (isJourneySelected) {
        setIsLoading(true);
        setUserAnswers((prev) => ({
          ...prev,
          [val.question]: {
            answer: answer,
            option: getKeyByValue(val.answers, answer),
            ...val,
          },
        }));
      }
    }
  };

  const capitalize = (val: string) =>
    val.charAt(0).toUpperCase() + val.slice(1);

  const getCustomizedQuestion = (question: string) => {
    const arr = question.split("^");
    const welcome = Object.keys(obj)[0] || "welcome";
    const name = capitalize(
      userSelection[obj[welcome as keyof typeof obj][0].question]?.answer.split(
        " "
      )[0]
    );
    arr.splice(1, 1, `${name}!`);
    return arr.join(" ")?.replace(/ ,/g, ",");
  };

  const backendapi = process.env.REACT_APP_BACKEND_API_URL;
  const firstname = userSelection["Hi, what is your name?"]?.answer;
  localStorage.setItem("firstname", firstname);
  const email =
    userSelection["Nice to meet you ^name^ Please enter your email."]?.answer;
  localStorage.setItem("email", email);
  const journeychoice =
    userSelection["What are you looking for today?"]?.option;
  const choice1 =
    userSelection["What best describes your style?"]?.option ||
    userSelection["Who are you buying for?"]?.option ||
    userSelection["What is the vibe of the event?"]?.option;
  const choice2 =
    userSelection["How do you live your life?"]?.option ||
    userSelection["Which mantra best aligns with how they live their life?"]
      ?.option ||
    userSelection[
      "Which mantra best aligns with how the group lives their lives?"
    ]?.option;
  const choice3 =
    userSelection["What flavor combination best suits your palate?"]?.option ||
    userSelection["What flavor combination best suits their palate?"]?.option ||
    userSelection[`What flavor combination best suits the group’s palate?`]
      ?.option;

  useEffect(() => {
    const getMostSelectedChoice = () => {
      const mostlySelected = getPredominantChoice();
      switch (mostlySelected) {
        case "A":
          return "Mostly A";
        case "B":
          return "Mostly B";
        case "C":
          return "Mostly C";
        default:
          return "No Dominant";
      }
    };
    const createUserJourneyPayload = {
      userid: `${email}1`,
      journeychoice: journeychoice,
      journeychoicetime: getCurrentDateAndTime(),
      choice1: choice1,
      choicedatetime1: getCurrentDateAndTime(),
      choice2: choice2,
      choicedatetime2: getCurrentDateAndTime(),
      choice3: choice3,
      choicedatetime3: getCurrentDateAndTime(),
      recommendation: getMostSelectedChoice(),
      user: email,
      attemptid: 1,
      user_id: userid,
    };
    const createUserJourney = async () => {
      try {
        const response = await fetch(`${backendapi}userJourney`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(createUserJourneyPayload),
        });
        if (response.ok) {
          await response.json();
        } else {
          throw new Error("API request failed");
        }
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    };
    createUserJourney();
  }, [userSelection]);

  let brandImage = getPredominantChoice();

  useEffect(() => {
    if (isJourneySelected) {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  }, [isJourneySelected, userSelection]);

  useEffect(() => {
    document.addEventListener("gesturestart", function (e) {
      e.preventDefault();
    });
    return () =>
      document.removeEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
  }, []);

  useEffect(() => {
    if (index === 3 && isJourneySelected) {
      setIsLastLoading(true);
      if (!isLoading) {
        setTimeout(() => {
          setIsLastLoading(false);
        }, 3000);
      }
    }
  }, [index, isJourneySelected, isLoading]);
  return (
    <>
      <div className="guestuserwrapper">
        <Header />
        <div className="chat-view-wrapper">
          <Profile />

          <div className="chat-container">
            {Object.keys(userSelection).map(
              (item: keyof typeof userSelection) => (
                <Fragment key={userSelection[item].question}>
                  <IncomingMessage isQuestion={true}>
                    <p className="queTxt">
                      {userSelection[item].question.includes("^")
                        ? parse(
                            getCustomizedQuestion(userSelection[item].question)
                          )
                        : parse(userSelection[item].question)}
                    </p>
                    {Object.values(userSelection[item].answers)
                      .filter((item) => item)
                      ?.map((item: string) => (
                        <div className="ansOptions" key={item}>
                          {item}
                        </div>
                      ))}
                  </IncomingMessage>

                  {userSelection[item].answer && (
                    <OutgoingMessage isRecentMsg={false}>
                      {parse(userSelection[item].answer?.toUpperCase())}
                    </OutgoingMessage>
                  )}
                  <div ref={messagesEndRef} />
                </Fragment>
              )
            )}
            {isLoading ? (
              <IncomingMessage isQuestion={true}>
                <Typing />
              </IncomingMessage>
            ) : (
              index < data.length && (
                <>
                  {index === 3 && isJourneySelected && (
                    <IncomingMessage isQuestion={true}>
                      <p className="queTxt">
                        {parse(
                          `Thanks, ${fname}! <br/> <br/> Your personalized Glenfiddich recommendation will appear shortly. <br/> <br/> Sláinte! 🥃`
                        )}
                      </p>
                    </IncomingMessage>
                  )}
                  <>
                    {isLastLoading ? (
                      <IncomingMessage isQuestion={true}>
                        <Typing />
                      </IncomingMessage>
                    ) : (
                      <>
                        <IncomingMessage isQuestion={true}>
                          <p className="queTxt">
                            {!isJourneySelected &&
                            data[index].question.includes("^")
                              ? getCustomizedQuestion(data[index].question)
                              : parse(data[index].question)}
                          </p>
                          {Object.values(data?.[index].answers)
                            .filter((item) => item)
                            ?.map((item: string) => (
                              <div
                                className="ansOptions"
                                key={item}
                                onClick={() => handleAnswerSelections(item)}
                              >
                                {parse(item)}
                              </div>
                            ))}
                        </IncomingMessage>
                        {index === 3 && isJourneySelected && (
                          <IncomingMessage
                            isQuestion={false}
                            isRecommendation={true}
                          >
                            <img
                              width={250}
                              height={361}
                              src={`/recommendation/${brandImage}.png`}
                              alt="recommendation"
                              className="recommendation"
                            />
                            <br />
                            {/* <p className="final">
                              Success - You're entered for a chance to receive
                              your recommended bottle! Winners will be notified
                              post NCM.
                            </p> */}
                          </IncomingMessage>
                        )}
                      </>
                    )}
                  </>
                </>
              )
            )}
          </div>
          <div ref={messagesEndRef} />
        </div>

        <Message
          index={index}
          handleSubmit={handleAnswerSelections}
          isJourneySelected={isJourneySelected}
        />
      </div>
    </>
  );
};

export default ChatBox;
